import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $content = $('.animated-text');
    if (!$content.length) {
        return;
    }

    const interval = parseInt($content.data('interval'), 10) || 50;
    const paragraphs = $content
        .text()
        .split('\n')
        .map((sentence) => sentence.split('|').map((w) => {
            const word = w.trim();
            return word.length ? `${word} ` : word;
        }));
    $content.text('').removeClass('animated-text--hide');
    let sentenceIndex = 0;
    let wordIndex = 0;

    function incrementAnimation() {
        $content.text((wordIndex ? $content.text() : '') + paragraphs[sentenceIndex][wordIndex]);
        wordIndex += 1;

        if (wordIndex >= paragraphs[sentenceIndex].length) {
            wordIndex = 0;
            sentenceIndex += 1;

            if (sentenceIndex >= paragraphs.length) {
                sentenceIndex = 0;
            }
        }
    }

    incrementAnimation();
    setInterval(incrementAnimation, interval);
});
